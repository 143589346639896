import {
  GET_PROJECTS_LOADING,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  GET_PROJECT_LOADING,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAIL,
  ADD_PROJECT_LOADING,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  DELETE_PROJECT_LOADING,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  ARCHIVE_PROJECT_LOADING,
  ARCHIVE_PROJECT_SUCCESS,
  ARCHIVE_PROJECT_FAIL,
  UNARCHIVE_PROJECT_SUCCESS,  
  EDIT_PROJECT_LOADING,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_FAIL,
  CLEAR_PROJECT_ERROR,
  GET_PROJECT_PODMASTERS_LOADING,
  GET_PROJECT_PODMASTERS_SUCCESS,
  GET_PROJECT_PODMASTERS_FAIL,
} from '../types';

const initialState = {
  projects: [],
  project: {},
  isLoading: false,
  error: null,
};

// You could have an array [{ id: 1, isLoading: false, error: null, text: "Hey" }, { id: 2, isLoading: true, error: null, text: null }]

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_PROJECTS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROJECT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROJECT_PODMASTERS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_PROJECT_LOADING:
      return {
        ...state,
        projects: [
          {
            id: 0,
            title: 'Loading...',
            archived: false,
            projectPlan: '',
            risks: '',
            opportunities: '',
            resources: '',
            departments: [],
            isLoading: true,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            // user: { ...payload.project.user },
            user: { ...payload.me },
          },
          ...state.projects,
        ],
      };
    case DELETE_PROJECT_LOADING:
    case EDIT_PROJECT_LOADING:
      return {
        ...state,
        projects: state.projects.map((p) => {
          if (p.id === payload.id) return { ...p, isLoading: true };
          return p;
        }),
      };
    case ARCHIVE_PROJECT_LOADING:
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        projects: payload.projects,
      };
    case GET_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        project: payload.project,
      };
    case GET_PROJECT_PODMASTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        project: payload.project,
      };
    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.map((p) => {
          if (p.id === 0) return payload.project;
          return p;
        }),
      };
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.filter((p) => p.id !== payload.project.id),
      };
    case EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.map((p) => {
          if (p.id === payload.project.id) return payload.project;
          return p;
        }),
      };
    case ARCHIVE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.filter((p) => p.id !== payload.project.id),
      };
    case UNARCHIVE_PROJECT_SUCCESS:
      return {
        ...state,
        projects: state.projects.filter((p) => p.id !== payload.project.id),
      };
    case DELETE_PROJECT_FAIL:
    case EDIT_PROJECT_FAIL:
      return {
        ...state,
        error: null,
        projects: state.projects.map((p) => {
          if (p.id === payload.id) return { ...p, isLoading: false, error: payload.error };
          return p;
        }),
      };
    case ARCHIVE_PROJECT_FAIL:    
    case GET_PROJECTS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case GET_PROJECT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case GET_PROJECT_PODMASTERS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case ADD_PROJECT_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
        projects: state.projects.filter((p) => p.id !== 0),
      };
    case CLEAR_PROJECT_ERROR:
      return {
        ...state,
        projects: state.projects.map((p) => {
          if (p.id === payload.id) return { ...p, isLoading: false, error: null };
          return p;
        }),
      };
    default:
      return state;
  }
}
