// import React, { useState, useEffect } from 'react';
import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
// import { Link, withRouter } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { useFormik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCheck, faXmark, faPen, faCircle, faTrash, faBarsStaggered } from '@fortawesome/free-solid-svg-icons';


import requireAuth from '../../hoc/requireAuth';

// import Loader from '../../components/Loader/Loader';

import {  deleteTask, editTask, clearTaskError } from '../../store/actions/taskActions';
// import { getProjectTasks } from '../../store/actions/taskActions';
import { taskFormSchema } from './validation';

import './styles.css';

// import {useDrag, useDrop, onDragEnd, DragPreview} from 'react-aria';
import {useDrag} from 'react-aria';

const Task = ({ 
  task, 
  taskIsLoading, 
  taskError, 
  project, 
  department, 
  departments, 
  auth, 
  deleteTask, 
  editTask, 
  // clearTaskError, 
  hideApproveDecline,
  deconstructTask,
  deconstructValues,
  desconstructAudit
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  const [moved, setMoved] = useState(false);

  


  const formik = useFormik({
    initialValues: {
      title: task.title,
      description: task.description,
      complete: task.complete,
      assignedTo: task.assignedTo ? task.assignedTo.id : '',
      department: task.department.id,
      project: task.project.id,
      approved: task.approved,
      delay: task.delay,
      dueDate: typeof task.dueDate === 'object' ? '' : moment(task.dueDate).format('YYYY-MM-DD'),
      audit: task.audit,
    },
    validationSchema: taskFormSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      
      let setDelay = task.delay;
      if ((typeof task.dueDate !== 'object') && (task.approved === true)) {
        if (moment(values.dueDate).format('YYYY-MM-DD') > moment(task.dueDate).format('YYYY-MM-DD') && task.delay < 3) {
          setDelay = setDelay+1;
        }
      }
      
      // Reset assignedTo if department is changed
      if (values.department !== task.department.id) {
        values.assignedTo = '';
      }

      let audit = desconstructAudit(task.audit);
      if ((task.approved === true) || (task.audit.length === 1)) {        
        
        audit.push({
          user: auth.me.id,
          action: 'updated',
          date: moment().toISOString(),
          old: deconstructTask(task),
          new: deconstructValues(values),
        });
      } else {
        let lastChange = audit.pop();
        lastChange.new = values;
        audit.push(lastChange);
      }

      
      

      editTask(task.id, { 
        title: values.title, 
        description: values.description, 
        complete: values.complete, 
        assignedTo: values.assignedTo === '' ? null : values.assignedTo, 
        department: values.department, 
        project: task.project.id, // NOTE: this is not the formik value, it is the task's project id
        approved: false, // NOTE: this is not the formik value
        delay: setDelay, 
        dueDate: moment(values.dueDate).toISOString(),
        audit: audit,
      });      
      // resetForm();
      setSubmitting(false);      
      
      setIsEdit(false);
      // toggleTaskFormVisibility(department.id);
    },
  });

  

  

  

  const completeTask = (id) => {
    
    let audit = desconstructAudit(task.audit);      
    audit.push({
      user: auth.me.id,
      action: 'completed',
      date: new Date().toISOString().split('T')[0],
      old: {},
      new: {},
    });
      

    editTask(id, { 
      complete: true,
      approved: task.approved,
      delay: task.delay,
      dueDate: typeof task.dueDate === 'object' ? '' : task.dueDate,
      assignedTo: task.assignedTo.id,
      department: task.department.id,
      project: task.project.id, 
      title: task.title,
      description: task.description,
      audit: audit,
    });
    // getProjectTasks(task.project.id);
  }

  const approveTask = (id) => {
    // console.log('approveTask', id);
    let audit = desconstructAudit(task.audit);      
    audit.push({
      user: auth.me.id,
      action: 'approved',
      date: new Date().toISOString().split('T')[0],
      old: {},
      new: {},
    });

    editTask(id, { 
      complete: task.complete,
      approved: true,
      delay: task.delay,
      dueDate: typeof task.dueDate === 'object' ? '' : task.dueDate,      
      assignedTo: task.assignedTo.id,
      department: task.department.id,
      project: task.project.id, 
      title: task.title,
      description: task.description,
      audit: audit,
    });
    // getProjectTasks(task.project.id);
  }
  
  const removeTask = (id, action) => {
    
    if (action === "delete") {
      deleteTask(id);
    } else if (action === "decline") {
      
      if (task.audit.length > 1) {
        let audit = desconstructAudit(task.audit);
        let lastAudit = audit.pop();
        lastAudit.old.audit = audit;
        editTask(id, lastAudit.old);
      } else {
        deleteTask(id);
      }
    }
    setIsConfirm(false);
  }

  const openEditTaskForm = () => {
    setIsEdit(true);
  }

  const closeEditTaskForm = () => {
    setIsEdit(false);
  }

  const openConfirmDeleteTaskForm = () => {
    setIsConfirm(true);
  }

  const closeConfirmDeleteTaskForm = () => {
    setIsConfirm(false);
  }

  const canEditTask = (task) => {
    return auth.me.role === 'SUPERADMIN' || task.department.users.some(user => user.toString() === auth.me.id.toString()) || project.podMasters.some(pm => pm.id === auth.me.id);
  }

  const canAdminTask = () => {
    return auth.me.role === 'SUPERADMIN' || project.podMasters.some(pm => pm.id === auth.me.id);
  }

  let { dragProps, isDragging } = useDrag({
    getItems() {
      // console.log('useDrag task', task);
      return [{
        'text/plain': task.id,
        'task': task
      }];
    },
    isDisabled: !canEditTask(task),
    onDragEnd(e) {
      if (e.dropOperation === 'move') {
        setMoved(true);        
      }
    }
  });

  if (moved) {
    // find a better way to handle this for the scenario where the task is moved to the same location
    setMoved(false);
    // return null;    
    

  }

  return (
    <div className={taskIsLoading ? 'task loader' : 'task'}>
      {/* {taskError && <div className="error-center">{taskError}</div>}         */}
        {/* {taskIsLoading ? (
          <Loader />
        ) : (  */}
        {isEdit ? (
          <>
            <div className="task-form-background-overlay" onClick={() => closeEditTaskForm()}></div>
            <div className='edit-task-form-container'>
              <div className="task-form" style={{backgroundColor: department.color}}>
                {!isConfirm ? (
                  <>
                    <h2 className='task-form-title-edit'>Edit Task 
                      {canAdminTask() ? 
                        <div className='task-form-buttons-admin'>                      
                          <div className="task-form-buttons-admin-archive" onClick={() => completeTask(task.id)}><FontAwesomeIcon icon={faCheck} className='task-form-buttons-archive-icon'/> Done</div>
                          <div className="task-form-buttons-admin-delete" onClick={() => openConfirmDeleteTaskForm()}><FontAwesomeIcon icon={faTrash} className='task-form-buttons-delete-icon'/> Delete</div>
                          
                        </div>
                      : null}
                    </h2>
                    <div className='task-form-department'>{department.name} - Created by {task.user.name} on {moment(task.createdAt).format('MMM DD, YYYY')}</div>
                    <form onSubmit={formik.handleSubmit}>        

                      <h4 className='label'>Task Title</h4>
                      {/* Task Title */}
                      <input
                        name="title"          
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.title}
                        disabled={false}
                      />            
                      {(formik.touched.title && formik.errors.title) ? (
                        <p className="error">{formik.errors.title}</p>
                      ) : null}

                      <h4 className='label'>Task Description</h4>
                      {/* Task Description */}
                      <textarea
                        name="description"
                        rows="3"
                        cols="30"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                        disabled={false}
                      />
                      {formik.touched.description && formik.errors.description ? (
                        <p className="error">{formik.errors.description}</p>
                      ) : null}

                      <h4 className='label'>Assigned To</h4>
                      {/* Assigned To */}
                      <select
                        name="assignedTo"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.assignedTo}
                        disabled={false}
                        className='task-form-select'
                      >
                        <option value="">None</option>
                        {department.users.map((user) => (
                          <option key={user.id} value={user.id}>{user.name}</option>
                        ))}
                      </select>
                      {formik.touched.assignedTo && formik.errors.assignedTo ? (
                        <p className="error">{formik.errors.assignedTo}</p>
                      ) : null}

                      <h4 className='label'>Department</h4>
                      {/* Department */}
                      <select
                        name="department"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.department}
                        disabled={false}
                        className='task-form-select'
                      >
                        
                        {departments.map((dept) => (
                          <option key={dept.id} value={dept.id}>{dept.name}</option>
                        ))}
                      </select>
                      {formik.touched.department && formik.errors.department ? (
                        <p className="error">{formik.errors.department}</p>
                      ) : null}

                      <h4 className='label'>Due Date</h4>
                      {/* Due Date */}
                      <input
                        name="dueDate"
                        type="date"
                        min={moment().add(-1, 'days').format('YYYY-MM-DD')}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.dueDate}
                        disabled={false}
                      />
                      {formik.touched.dueDate && formik.errors.dueDate ? (
                        <p className="error">{formik.errors.dueDate}</p>
                      ) : null}


                      
                      <div className='task-form-buttons'>
                        <button type="submit" className="btn task-form-buttons-update" value="Update" disabled={false}>Update</button>
                        <button type="button" className="btn task-form-buttons-cancel" onClick={() => closeEditTaskForm()}>Cancel</button>
                      </div>
                      
                      
                    </form>
                  </>
                ) : 
                (
                  <>
                    <p className="task-form-buttons-admin-confirm-text">Are you sure you want to delete <b>{task.title}</b> task?</p>
                    <div className="task-form-buttons-admin-confirm">                      
                      <div className="btn task-form-buttons-admin-confirm-delete" onClick={() => removeTask(task.id, "delete")}> Confirm</div>
                      <div className="btn task-form-buttons-admin-confirm-cancel" onClick={() => closeConfirmDeleteTaskForm()}> Cancel</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : ( null )}

          <div 
            {...dragProps}
            role="button"
            tabIndex={0}
            className={`task-box draggable ${isDragging ? 'dragging' : ''}`} 
            style={{backgroundColor: department.color}}
          >
            
            <div className="task-info">              

              {/* Task Info Top */}
              <div className='task-info-top'>


                <div className='task-info-top-left'>
                  {(task.assignedTo.name) ?
                    <>
                      {/* <div className='task-assignedTo'><FontAwesomeIcon icon={faUser} className='task-assignedTo-icon'/>{task.assignedTo.name}</div>*/}
                      <div className='task-assignedTo'><img src={process.env.REACT_APP_SERVER_BASE_URL+task.assignedTo.avatar} alt={task.assignedTo.name} className='task-assignedTo-avatar'/></div>
                    </>
                  : null}
                  
                  {task.description !== '' ? 
                    <>
                      { canEditTask(task) ? (
                        <div className='task-description'>
                          <FontAwesomeIcon icon={faBarsStaggered} className='task-description-icon' onClick={() => openEditTaskForm()}/>                  
                        </div>
                      ) : (
                        <div className='task-description-noedit'>
                          <FontAwesomeIcon icon={faBarsStaggered} className='task-description-icon' />                  
                        </div>
                      )}                  
                    </>
                  : null}
                  

                  { canEditTask(task) ? ( 
                    <div className="task-edit"><FontAwesomeIcon icon={faPen} className='task-edit-icon' onClick={() => openEditTaskForm()}/></div>
                  ) : null }

                </div>


                <div className='task-info-top-right'>
                  {task.delay == 1 ? 
                    <div className='task-delay'>
                      {/* <FontAwesomeIcon icon={faCircle} className='task-delay-icon'/> */}
                      <div className='task-delay-bar'></div>
                    </div>
                  : null}
                  {task.delay == 2 ? 
                    <div className='task-delay'>
                      {/* <FontAwesomeIcon icon={faCircle} className='task-delay-icon'/>
                      <FontAwesomeIcon icon={faCircle} className='task-delay-icon'/> */}
                      <div className='task-delay-bar'></div>
                      <div className='task-delay-bar'></div>
                    </div>
                  : null}
                  {task.delay == 3 ? 
                    <div className='task-delay'>
                      {/* <FontAwesomeIcon icon={faCircle} className='task-delay-icon'/>
                      <FontAwesomeIcon icon={faCircle} className='task-delay-icon'/>
                      <FontAwesomeIcon icon={faCircle} className='task-delay-icon'/> */}
                      <div className='task-delay-bar'></div>
                      <div className='task-delay-bar'></div>
                      <div className='task-delay-bar'></div>
                    </div>
                  : null}
                </div>
                

              </div>

              {(task.dueDate !== null && typeof task.dueDate !== 'object' && moment(task.dueDate) < moment().add(-1, 'days')) || (task.delay === 3) ? 
                <div className='task-due-date'>{moment(task.dueDate).format('MMM DD, YYYY')}</div> 
              : null}

              

              
              {/* Task Title */}
              { canEditTask(task) ? (
                <div className='task-header-row' onClick={() => openEditTaskForm()}>
                  <div className='task-title-large' style={{ fontSize: project.fontSizeLarge, lineHeight: project.fontSizeLarge, fontFamily: project.fontStyle }}>{task.title}</div>
                  <div className='task-title-small' style={{ fontSize: project.fontSizeSmall, lineHeight: project.fontSizeSmall, fontFamily: project.fontStyle }}>{task.title}</div>                  
                </div>
              ) : (
                <div className='task-header-row-noedit'>
                  <div className='task-title-large' style={{ fontSize: project.fontSizeLarge, lineHeight: project.fontSizeLarge, fontFamily: project.fontStyle }}>{task.title}</div>
                  <div className='task-title-small' style={{ fontSize: project.fontSizeSmall, lineHeight: project.fontSizeSmall, fontFamily: project.fontStyle }}>{task.title}</div>
                </div>
              )}
              

            </div>

            <div className="task-actions">

              
              {canAdminTask() && !task.approved && !hideApproveDecline ? 
                <div className="task-actions-approval">
                  <div className='task-actions-approval-buttons'>
                    <button className='task-approve-button' onClick={() => approveTask(task.id)}><FontAwesomeIcon icon={faCheck} className='task-approve-button-icon' /></button>
                    <button className='task-decline-button' onClick={() => removeTask(task.id, "decline")}><FontAwesomeIcon icon={faXmark} className='task-decline-button-icon' /></button>
                  </div>
                  <div className='task-actions-approval-last-audit'><span className='task-actions-approval-last-audit-action'>{task.audit[task.audit.length - 1].action}</span> on <span className='task-actions-approval-last-audit-date'>{moment(task.audit[task.audit.length - 1].date.split('T')[0]).format('MMM DD')}</span> by <span className='task-actions-approval-last-audit-user'>{task.audit[task.audit.length - 1].user.name}</span></div>
                  {/* <div className='task-actions-approval-last-audit'>{task.audit[task.audit.length - 1].action} on {moment(task.audit[task.audit.length - 1].date).format('MMM DD, YYYY')}</div> */}
                </div>
              : null}
              
              {(task.dueDate !== null && typeof task.dueDate !== 'object' && moment(task.dueDate) < moment().add(-1, 'days')) && canAdminTask() && !task.complete ? 
                <div className="task-actions-complete">
                  <button className='task-done-button' onClick={() => completeTask(task.id)}><FontAwesomeIcon icon={faCheck} className='task-done-button-icon' /></button>
                </div>
              : null}
              
            </div>
            
          </div>
        {/* )} */}
      
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  // task: state.task,
});

export default compose(requireAuth, withRouter, connect(mapStateToProps, { deleteTask, editTask, clearTaskError }))(Task);
