import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Layout from '../../layout/Layout';
// import MessageList from '../../components/MessageList/MessageList';
import ActiveProjectList from '../../components/ActiveProjectList/ActiveProjectList';
import ArchivedProjectList from '../../components/ArchivedProjectList/ArchivedProjectList';
import ProjectForm from '../../components/ProjectForm/ProjectForm';
import requireAuth from '../../hoc/requireAuth';

import './styles.css';

const Dashboard = ({auth}) => {  
  
  if (auth.isAuthenticated) {
    return (    
      <Layout>
        <div className="dashboard-page">          
          <div className="dashboard-quote">
            <p className="dashboard-quote-text">"Great things in business are never done by one person. They’re done by a team of people."</p>
            <p className="dashboard-quote-author">- Steve Jobs</p>
          </div>
          
          <div className="project-list-container">          
              <ActiveProjectList />
              { (auth.me?.role === 'SUPERADMIN') || (auth.me?.role === 'PODMASTER') ? (
                <ProjectForm />
              ) : null }
              
            
          </div>
          
        </div>
      </Layout>
      
    );
  } else {
    return null;
  }
  
};

const mapStateToProps = (state) => ({
  auth: state.auth,  
});

export default compose(requireAuth, connect(mapStateToProps))(Dashboard);
