export const LOGIN_WITH_OAUTH_LOADING = 'LOGIN_WITH_OAUTH_LOADING';
export const LOGIN_WITH_OAUTH_SUCCESS = 'LOGIN_WITH_OAUTH_SUCCESS';
export const LOGIN_WITH_OAUTH_FAIL = 'LOGIN_WITH_OAUTH_FAIL';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const LOGIN_WITH_EMAIL_LOADING = 'LOGIN_WITH_EMAIL_LOADING';
export const LOGIN_WITH_EMAIL_SUCCESS = 'LOGIN_WITH_EMAIL_SUCCESS';
export const LOGIN_WITH_EMAIL_FAIL = 'LOGIN_WITH_EMAIL_FAIL';

export const REGISTER_WITH_EMAIL_LOADING = 'REGISTER_WITH_EMAIL_LOADING';
export const REGISTER_WITH_EMAIL_SUCCESS = 'REGISTER_WITH_EMAIL_SUCCESS';
export const REGISTER_WITH_EMAIL_FAIL = 'REGISTER_WITH_EMAIL_FAIL';

export const ME_LOADING = 'ME_LOADING';
export const ME_SUCCESS = 'ME_SUCCESS';
export const ME_FAIL = 'ME_FAIL';

export const GET_FEATURE = 'GET_FEATURE';
export const GET_PROFILE = 'GET_PROFILE';

export const GET_MESSAGES_LOADING = 'GET_MESSAGES_LOADING';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAIL = 'GET_MESSAGES_FAIL';

export const ADD_MESSAGE_LOADING = 'ADD_MESSAGE_LOADING';
export const ADD_MESSAGE_SUCCESS = 'ADD_MESSAGE_SUCCESS';
export const ADD_MESSAGE_FAIL = 'ADD_MESSAGE_FAIL';

export const DELETE_MESSAGE_LOADING = 'DELETE_MESSAGE_LOADING';
export const DELETE_MESSAGE_SUCCESS = 'DELETE_MESSAGE_SUCCESS';
export const DELETE_MESSAGE_FAIL = 'DELETE_MESSAGE_FAIL';

export const EDIT_MESSAGE_LOADING = 'EDIT_MESSAGE_LOADING';
export const EDIT_MESSAGE_SUCCESS = 'EDIT_MESSAGE_SUCCESS';
export const EDIT_MESSAGE_FAIL = 'EDIT_MESSAGE_FAIL';
export const CLEAR_MESSAGE_ERROR = 'CLEAR_MESSAGE_ERROR';

export const GET_USERS_LOADING = 'GET_USERS_LOADING';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const GET_PROFILE_LOADING = 'GET_PROFILE_LOADING';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';

export const EDIT_USER_LOADING = 'EDIT_USER_LOADING';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';

export const DELETE_USER_LOADING = 'DELETE_USER_LOADING';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const RESEED_DATABASE_LOADING = 'RESEED_DATABASE_LOADING';
export const RESEED_DATABASE_SUCCESS = 'RESEED_DATABASE_SUCCESS';
export const RESEED_DATABASE_FAIL = 'RESEED_DATABASE_FAIL';

export const RESEED_PROJECTS_LOADING = 'RESEED_PROJECTS_LOADING';
export const RESEED_PROJECTS_SUCCESS = 'RESEED_PROJECTS_SUCCESS';
export const RESEED_PROJECTS_FAIL = 'RESEED_PROJECTS_FAIL';

export const GET_PROJECTS_LOADING = 'GET_PROJECTS_LOADING';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAIL = 'GET_PROJECTS_FAIL';

export const GET_PROJECT_LOADING = 'GET_PROJECT_LOADING';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAIL = 'GET_PROJECT_FAIL';

export const ADD_PROJECT_LOADING = 'ADD_PROJECT_LOADING';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAIL = 'ADD_PROJECT_FAIL';

export const DELETE_PROJECT_LOADING = 'DELETE_PROJECT_LOADING';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL';

export const ARCHIVE_PROJECT_LOADING = 'ARCHIVE_PROJECT_LOADING';
export const ARCHIVE_PROJECT_SUCCESS = 'ARCHIVE_PROJECT_SUCCESS';
export const ARCHIVE_PROJECT_FAIL = 'ARCHIVE_PROJECT_FAIL';

export const UNARCHIVE_PROJECT_SUCCESS = 'UNARCHIVE_PROJECT_SUCCESS';

export const EDIT_PROJECT_LOADING = 'EDIT_PROJECT_LOADING';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_FAIL = 'EDIT_PROJECT_FAIL';
export const CLEAR_PROJECT_ERROR = 'CLEAR_PROJECT_ERROR';

export const GET_PROJECT_PODMASTERS_LOADING = 'GET_PROJECT_PODMASTERS_LOADING';
export const GET_PROJECT_PODMASTERS_SUCCESS = 'GET_PROJECT_PODMASTERS_SUCCESS';
export const GET_PROJECT_PODMASTERS_FAIL = 'GET_PROJECT_PODMASTERS_FAIL';


export const GET_DEPARTMENTS_LOADING = 'GET_DEPARTMENTS_LOADING';
export const GET_DEPARTMENTS_SUCCESS = 'GET_DEPARTMENTS_SUCCESS';
export const GET_DEPARTMENTS_FAIL = 'GET_DEPARTMENTS_FAIL';

export const GET_DEPARTMENT_LOADING = 'GET_DEPARTMENT_LOADING';
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';
export const GET_DEPARTMENT_FAIL = 'GET_DEPARTMENT_FAIL';

export const GET_DEPARTMENTS_PROFILE_LOADING = 'GET_DEPARTMENTS_PROFILE_LOADING';
export const GET_DEPARTMENTS_PROFILE_SUCCESS = 'GET_DEPARTMENTS_PROFILE_SUCCESS';
export const GET_DEPARTMENTS_PROFILE_FAIL = 'GET_DEPARTMENTS_PROFILE_FAIL';

export const ADD_DEPARTMENT_LOADING = 'ADD_DEPARTMENT_LOADING';
export const ADD_DEPARTMENT_SUCCESS = 'ADD_DEPARTMENT_SUCCESS';
export const ADD_DEPARTMENT_FAIL = 'ADD_DEPARTMENT_FAIL';

export const DELETE_DEPARTMENT_LOADING = 'DELETE_DEPARTMENT_LOADING';
export const DELETE_DEPARTMENT_SUCCESS = 'DELETE_DEPARTMENT_SUCCESS';
export const DELETE_DEPARTMENT_FAIL = 'DELETE_DEPARTMENT_FAIL';

export const EDIT_DEPARTMENT_LOADING = 'EDIT_DEPARTMENT_LOADING';
export const EDIT_DEPARTMENT_SUCCESS = 'EDIT_DEPARTMENT_SUCCESS';
export const EDIT_DEPARTMENT_FAIL = 'EDIT_DEPARTMENT_FAIL';
export const CLEAR_DEPARTMENT_ERROR = 'CLEAR_DEPARTMENT_ERROR';


export const GET_TASKS_LOADING = 'GET_TASKS_LOADING';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASKS_FAIL = 'GET_TASKS_FAIL';

export const GET_PROJECT_TASKS_LOADING = 'GET_PROJECT_TASKS_LOADING';
export const GET_PROJECT_TASKS_SUCCESS = 'GET_PROJECT_TASKS_SUCCESS';
export const GET_PROJECT_TASKS_FAIL = 'GET_PROJECT_TASKS_FAIL';

export const GET_USER_TASKS_LOADING = 'GET_USER_TASKS_LOADING';
export const GET_USER_TASKS_SUCCESS = 'GET_USER_TASKS_SUCCESS';
export const GET_USER_TASKS_FAIL = 'GET_USER_TASKS_FAIL';

export const GET_ASSIGNEDTO_TASKS_LOADING = 'GET_ASSIGNEDTO_TASKS_LOADING';
export const GET_ASSIGNEDTO_TASKS_SUCCESS = 'GET_ASSIGNEDTO_TASKS_SUCCESS';
export const GET_ASSIGNEDTO_TASKS_FAIL = 'GET_ASSIGNEDTO_TASKS_FAIL';

export const GET_DEPARTMENT_TASKS_LOADING = 'GET_DEPARTMENT_TASKS_LOADING';
export const GET_DEPARTMENT_TASKS_SUCCESS = 'GET_DEPARTMENT_TASKS_SUCCESS';
export const GET_DEPARTMENT_TASKS_FAIL = 'GET_DEPARTMENT_TASKS_FAIL';

export const GET_TASK_LOADING = 'GET_TASK_LOADING';
export const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS';
export const GET_TASK_FAIL = 'GET_TASK_FAIL';

export const ADD_TASK_LOADING = 'ADD_TASK_LOADING';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_FAIL = 'ADD_TASK_FAIL';

export const DELETE_TASK_LOADING = 'DELETE_TASK_LOADING';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAIL = 'DELETE_TASK_FAIL';

export const EDIT_TASK_LOADING = 'EDIT_TASK_LOADING';
export const EDIT_TASK_SUCCESS = 'EDIT_TASK_SUCCESS';
export const EDIT_TASK_FAIL = 'EDIT_TASK_FAIL';
export const CLEAR_TASK_ERROR = 'CLEAR_TASK_ERROR';