import { combineReducers } from 'redux';

import authReducer from './authReducer';
import registerReducer from './registerReducer';
import userReducer from './userReducer';
import usersReducer from './usersReducer';
import messageReducer from './messageReducer';
import projectReducer from './projectReducer';
import departmentReducer from './departmentReducer';
import taskReducer from './taskReducer';

export default combineReducers({
  auth: authReducer,
  register: registerReducer,
  message: messageReducer,
  user: userReducer,
  users: usersReducer,
  project: projectReducer,
  department: departmentReducer,  
  task: taskReducer,
});
