// import React, { useState, useEffect } from 'react';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen, faBoxArchive, faTrashArrowUp } from '@fortawesome/free-solid-svg-icons';

import { deleteProject, editProject, clearProjectError } from '../../store/actions/projectActions';
// import { projectFormSchema } from './validation';

import './styles.css';

const Project = ({ project, archived, auth, deleteProject, editProject, clearProjectError }) => { 

  const handleArchive = (e, id, formData) => {
    e.preventDefault();    
      editProject(id, formData);    
  };

  const checkPermissions = () => {
    if (auth.me.id === project.user.id || auth.me.role === 'SUPERADMIN' || project.podMasters.some((podMaster) => podMaster.id === auth.me.id)) {
      return true;
    }
    return false;
  }  

  return (
    <div className={project.isLoading ? 'project loader' : 'project'}>
        <div className='project-info'>
          <h3 className='title'>{project.title}</h3>
          <div>                
            <span className="fullname">Created by {project.user.name}</span>            
            &nbsp;<span className="time">{moment(project.createdAt).fromNow()}</span>
            
          </div>
        </div>
        
        {auth.isAuthenticated && (
          <div className='project-actions'> 
            { !archived ? (
              <>
                <Link to={`/project/view/${project.id}`} className="btn"><FontAwesomeIcon icon={faEye} /> View</Link>
                {checkPermissions() && (
                  <>
                    <Link to={`/project/edit/${project.id}`} className="btn"><FontAwesomeIcon icon={faPen} /> Edit</Link>                    
                    <button onClick={(e) => handleArchive(e, project.id, {title: project.title, archived: !project.archived, projectPlan: project.projectPlan, risks: project.risks, opportunities: project.opportunities, resources: project.resources})} type="button" className="btn">
                    <FontAwesomeIcon icon={faBoxArchive} /> Archive
                    </button>
                  </>
                )}
                
                
              </>
            ) : (
              <>
                {checkPermissions() && (
                  <button onClick={(e) => handleArchive(e, project.id, {title: project.title, archived: !project.archived, projectPlan: project.projectPlan, risks: project.risks, opportunities: project.opportunities, resources: project.resources})} type="button" className="btn">
                    <FontAwesomeIcon icon={faTrashArrowUp} /> Unarchive
                  </button>
                )}                  
              </>
            )}
          </div>
        )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,  
});

export default connect(mapStateToProps, { deleteProject, editProject, clearProjectError })(Project);
