// import React, { useState, useEffect, useRef } from 'react';
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
// import { Link, withRouter } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';

import Loader from '../../components/Loader/Loader';
import Layout from '../../layout/Layout';
import requireAuth from '../../hoc/requireAuth';

import TaskForm from '../../components/TaskForm/TaskForm';
import Task from '../../components/Task/Task';
import DailyTaskCell from '../../components/DailyTaskCell/DailyTaskCell';
import HuddleBoards from '../../components/HuddleBoards/HuddleBoards';
import '../../components/DepartmentList/styles.css';

import { getProject, deleteProject, editProject, clearProjectError } from '../../store/actions/projectActions';
import { getProjectTasks } from '../../store/actions/taskActions';
import { getDepartments } from '../../store/actions/departmentActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import './styles.css';



// import socketIOClient from 'socket.io-client';

const deconstructTask = (task) => {
  return {
    title: task.title, 
    description: task.description, 
    complete: task.complete, 
    assignedTo: task.assignedTo.hasOwnProperty('id') ? task.assignedTo.id : null,
    department: task.department.id, 
    project: task.project.id,
    approved: task.approved,
    delay: task.delay, 
    dueDate: task.dueDate,
  }
}

const deconstructValues = (values) => {
  return {
    title: values.title, 
    description: values.description, 
    complete: values.complete, 
    assignedTo: values.assignedTo === '' ? null : values.assignedTo,
    department: values.department, 
    project: values.project,
    approved: values.approved,
    delay: values.delay, 
    dueDate: values.dueDate,
  }
}

const desconstructAudit = (audit) => {
  let cleanAudit = [];
  audit.forEach((a) => {
    cleanAudit.push({
      user: a.user.id,
      action: a.action,
      date: a.date,
      old: a.old,
      new: a.new,
    });
  });
  return cleanAudit;
}


const ProjectView = ({ 
  getProject, 
  getProjectTasks,
  getDepartments,
  auth,
  task: { tasks, isLoading: tasksIsLoading, error: tasksError },
  thisProject: { project, isLoading, error }, 
  department: { departments, isLoading: departmentsIsLoading, error: departmentsError },  
  editProject, 
  clearProjectError,   
  history, 
  match 
}) => {  
  
  // const socketio = socketIOClient(process.env.REACT_APP_SERVER_BASE_URL);

  const matchProjectId = match.params.projectId;
    
  const [isTaskFormVisible, setIsTaskFormVisible] = useState({ });
  
  const [isHuddleBoardVisible, setIsHuddleBoardVisible] = useState(false);
  
  const [departmentFilter, setDepartmentFilter] = useState({});
  
  const [taskToEdit, setTaskToEdit] = useState(null);

  const [secondsToRefresh, setSecondsToRefresh] = useState(10);

  const [hideApproveDecline, setHideApproveDecline] = useState(false);


  useEffect(() => {
    if (secondsToRefresh > -1) {
      const timerId = setInterval(() => {
        setSecondsToRefresh((prevSeconds) => prevSeconds - 1); // decrement seconds
      }, 1000);

      // Clean up the interval on component unmount or when seconds reach 0
      return () => clearInterval(timerId);
    } else {
      getProjectTasks(matchProjectId);
      // getProject(matchProjectId, true, history);
      // getDepartments(matchProjectId);      
      setSecondsToRefresh(10);
    }
  }, [secondsToRefresh]);

  const toggleHuddleBoardVisibility = () => {
    setIsHuddleBoardVisible(!isHuddleBoardVisible);
  }

  const toggleTaskFormVisibility = (departmentId) => {
    // console.log('isTaskFormVisible', isTaskFormVisible);
    setIsTaskFormVisible({
      // ...isTaskFormVisible,
      [departmentId]: !isTaskFormVisible[departmentId]
    });
  };

  const toggleDepartmentFilter = (departmentId) => {    
    setDepartmentFilter({    
      ...departmentFilter,
      [departmentId]: !departmentFilter[departmentId]
    });
  };

  const toggleHideApproveDecline = () => {
    setHideApproveDecline(!hideApproveDecline);
  }

  useEffect(() => {
    getProjectTasks(matchProjectId);
    getProject(matchProjectId, true, history);
    getDepartments(matchProjectId);
    
    // disable task form visibility for all departments
    departments.forEach((department) => {
      setIsTaskFormVisible({
        ...isTaskFormVisible,
        [department.id]: false
      });
    });    
    setSecondsToRefresh(10);
  }, []);

  const isAdmin = () => {    
    return auth.me.role === 'SUPERADMIN' || project.podMasters?.some(pm => pm.id === auth.me.id) || auth.me.id === project.user?.id;
  }
 
  if (auth.isAuthenticated) {
    return (
      <Layout>
        <div className="project-view-page">
          
          {error && <div className="error-center">{error}</div>}        
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {/* <TaskForm taskToEdit={taskToEdit} setTaskToEdit={setTaskToEdit}/> */}
              
              <div className='project-title-bar'>
                <div className='project-title-bar-left'>
                  <h1 className="project-view-page-title">{project.title}</h1>
                  <p className='project-view-page-refresh-in'>{secondsToRefresh > 0 ? `Next refresh in ${secondsToRefresh} seconds` : 'Fetching tasks...'}</p>
                </div>
                <div className='project-title-bar-right'>
                  {isAdmin() && <div className='project-title-bar-right-filter-container'><div className={`project-title-bar-right-filter-button ${ hideApproveDecline ? 'filter-active' : ''}`} onClick={toggleHideApproveDecline}>{hideApproveDecline ? 'DECISION MODE' : <>DECISION MODE &nbsp; <FontAwesomeIcon icon={faXmark} /></>}</div></div> }
                  <div className='project-title-bar-right-filter-container'>
                    <div className='project-title-bar-right-filter-title'>Departments:</div>
                    {departments.map((department) => (
                      <div key={department.id} className={`project-title-bar-right-filter-button-department ${ departmentFilter[department.id] ? 'filter-active' : ''}`} style={{backgroundColor: `${department.color}`}} onClick={() => toggleDepartmentFilter(department.id)}>{department.name}{departmentFilter[department.id] ? '' : <>&nbsp; <FontAwesomeIcon icon={faXmark} /></>}</div>
                    ))}
                  </div>
                  <div className='project-title-bar-right-filter-container'>
                    <div onClick={toggleHuddleBoardVisibility} className='project-title-bar-right-filter-button huddle-board-button'>Huddle Boards</div>
                    <div className={`huddle-boards-container ${isHuddleBoardVisible ? 'huddle-boards-container-active' : ''}`}><HuddleBoards toggleHuddleBoardVisibility={toggleHuddleBoardVisibility} /></div>
                  </div>
                  {/* <Draggable /> */}
                  {/* <DropTarget /> */}
                </div>
              </div>
              <div className="project-view-page-header">
                <div className="project-view-page-body-row-header-department-1"><div>Departments</div></div>
                <div className="project-view-page-body-row-header-1"><div>Pacing</div></div>
                <div className="project-view-page-body-row-header-1"><div>Escalate</div></div>
                <div className="project-view-page-body-row-header-1 due-column"><div>Due</div></div>
                <div className="project-view-page-body-row-header-2">{moment().format('dddd')}<span className='project-view-page-body-row-header-2-date'>{moment().format('MMM DD')}</span></div>
                <div className="project-view-page-body-row-header-2">{moment().add(1, 'days').format('dddd')}<span className='project-view-page-body-row-header-2-date'>{moment().add(1, 'days').format('MMM DD')}</span></div>
                <div className="project-view-page-body-row-header-2">{moment().add(2, 'days').format('dddd')}<span className='project-view-page-body-row-header-2-date'>{moment().add(2, 'days').format('MMM DD')}</span></div>
                <div className="project-view-page-body-row-header-2">{moment().add(3, 'days').format('dddd')}<span className='project-view-page-body-row-header-2-date'>{moment().add(3, 'days').format('MMM DD')}</span></div>
                <div className="project-view-page-body-row-header-2">{moment().add(4, 'days').format('dddd')}<span className='project-view-page-body-row-header-2-date'>{moment().add(4, 'days').format('MMM DD')}</span></div>
                <div className="project-view-page-body-row-header-2">{moment().add(5, 'days').format('dddd')}<span className='project-view-page-body-row-header-2-date'>{moment().add(5, 'days').format('MMM DD')}</span></div>
                <div className="project-view-page-body-row-header-2">{moment().add(6, 'days').format('dddd')}<span className='project-view-page-body-row-header-2-date'>{moment().add(6, 'days').format('MMM DD')}</span></div>
                <div className="project-view-page-body-row-header-2">{moment().add(7, 'days').format('dddd')}<span className='project-view-page-body-row-header-2-date'>{moment().add(7, 'days').format('MMM DD')}</span></div>
                <div className="project-view-page-body-row-header-2">{moment().add(8, 'days').format('dddd')}<span className='project-view-page-body-row-header-2-date'>{moment().add(8, 'days').format('MMM DD')}</span></div>
                <div className="project-view-page-body-row-header-2">{moment().add(9, 'days').format('dddd')}<span className='project-view-page-body-row-header-2-date'>{moment().add(9, 'days').format('MMM DD')}</span></div>
                <div className="project-view-page-body-row-header-2">{moment().add(10, 'days').format('dddd')}<span className='project-view-page-body-row-header-2-date'>{moment().add(10, 'days').format('MMM DD')}</span></div>
                <div className="project-view-page-body-row-header-2">{moment().add(11, 'days').format('dddd')}<span className='project-view-page-body-row-header-2-date'>{moment().add(11, 'days').format('MMM DD')}</span></div>
                <div className="project-view-page-body-row-header-2">{moment().add(12, 'days').format('dddd')}<span className='project-view-page-body-row-header-2-date'>{moment().add(12, 'days').format('MMM DD')}</span></div>
                <div className="project-view-page-body-row-header-2">{moment().add(13, 'days').format('dddd')}<span className='project-view-page-body-row-header-2-date'>{moment().add(13, 'days').format('MMM DD')}</span></div>
                
                

                

              </div>
              <div className="project-view-page-body">
                {departments.map((department) => (
                  <div key={department.id} className={`project-view-page-body-row ${ departmentFilter[department.id] ? 'hide-department' : '' }`}>
                    
                    
                    <div className='project-view-page-body-row-header-department-1'>
                      <div style={{margin: '10px'}}>
                        {isTaskFormVisible[department.id] && (
                          
                            <TaskForm department={department} projectID={project.id} isTaskFormVisible={isTaskFormVisible} toggleTaskFormVisibility={toggleTaskFormVisibility}/>
                          
                        )}                      
                      </div>
                      <div className='project-view-page-body-row-department-name'>
                        <div>{department.name}</div>
                        {auth.me.role === 'SUPERADMIN' || department.users.some(user => user.id === auth.me.id) || auth.me.id === project.user.id || project.podMasters.some(pm => pm.id === auth.me.id) ?
                          <div>                        
                            {isTaskFormVisible[department.id] ? null : 
                              <>
                                <button onClick={() => toggleTaskFormVisibility(department.id)} className='btn-add-task' style={{backgroundColor: `${department.color}`}}>
                                  + <span className='btn-add-task-tooltip'> 
                                    New Task
                                  </span>
                                </button>
                                
                              </>
                            }                            
                          </div>
                        : null}
                        
                      </div>
                    </div>


                    {/* Pacing */}                    
                    <DailyTaskCell 
                      dayNumber={0} 
                      department={department} 
                      tasks={tasks} 
                      departments={departments} 
                      project={project} 
                      hideApproveDecline={hideApproveDecline} 
                      auth={auth} 
                      deconstructTask={deconstructTask} 
                      deconstructValues={deconstructValues} 
                      desconstructAudit={desconstructAudit} 
                      cellType='pacing' 
                    />

                    {/* Escalate */}                    
                    <DailyTaskCell 
                      dayNumber={0} 
                      department={department} 
                      tasks={tasks} 
                      departments={departments} 
                      project={project} 
                      hideApproveDecline={hideApproveDecline} 
                      auth={auth} 
                      deconstructTask={deconstructTask} 
                      deconstructValues={deconstructValues} 
                      desconstructAudit={desconstructAudit} 
                      cellType='escalate' 
                    />


                    {/* Due */}
                    <div className={`project-view-page-body-row-task-cell ${ tasks.some(task => 
                        (task.department.id === department.id) && 
                        (task.dueDate !== null && typeof task.dueDate !== 'object' && task.delay < 3) &&
                        (moment(task.dueDate).format('YYYY-MM-DD') <= moment().add(-1, 'days').format('YYYY-MM-DD'))
                      ) ? '' : 'no-tasks' }`}>
                      <div className="project-view-page-body-row-header-responsive-1"><div><b>Due</b></div></div>
                      <div className='project-view-page-body-row-task-cell-tasks'>  
                        {tasks.filter(task => task.department.id === department.id).map(task => {
                          if (task.dueDate !== null && typeof task.dueDate !== 'object' && task.delay < 3) {
                            if (moment(task.dueDate).format('YYYY-MM-DD') <= moment().add(-1, 'days').format('YYYY-MM-DD')) {
                              
                              return <div key={task.id}><Task task={task} department={department} departments={departments} project={project} hideApproveDecline={hideApproveDecline} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} /></div>
                            }
                          }
                        })}
                      </div>
                    </div>                    

                    <DailyTaskCell dayNumber={0} department={department} tasks={tasks} departments={departments} project={project} hideApproveDecline={hideApproveDecline} auth={auth} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} cellType='daily' />
                    <DailyTaskCell dayNumber={1} department={department} tasks={tasks} departments={departments} project={project} hideApproveDecline={hideApproveDecline} auth={auth} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} cellType='daily' />
                    <DailyTaskCell dayNumber={2} department={department} tasks={tasks} departments={departments} project={project} hideApproveDecline={hideApproveDecline} auth={auth} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} cellType='daily' />
                    <DailyTaskCell dayNumber={3} department={department} tasks={tasks} departments={departments} project={project} hideApproveDecline={hideApproveDecline} auth={auth} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} cellType='daily' />
                    <DailyTaskCell dayNumber={4} department={department} tasks={tasks} departments={departments} project={project} hideApproveDecline={hideApproveDecline} auth={auth} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} cellType='daily' />
                    <DailyTaskCell dayNumber={5} department={department} tasks={tasks} departments={departments} project={project} hideApproveDecline={hideApproveDecline} auth={auth} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} cellType='daily' />
                    <DailyTaskCell dayNumber={6} department={department} tasks={tasks} departments={departments} project={project} hideApproveDecline={hideApproveDecline} auth={auth} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} cellType='daily' />
                    <DailyTaskCell dayNumber={7} department={department} tasks={tasks} departments={departments} project={project} hideApproveDecline={hideApproveDecline} auth={auth} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} cellType='daily' />
                    <DailyTaskCell dayNumber={8} department={department} tasks={tasks} departments={departments} project={project} hideApproveDecline={hideApproveDecline} auth={auth} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} cellType='daily' />
                    <DailyTaskCell dayNumber={9} department={department} tasks={tasks} departments={departments} project={project} hideApproveDecline={hideApproveDecline} auth={auth} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} cellType='daily' />
                    <DailyTaskCell dayNumber={10} department={department} tasks={tasks} departments={departments} project={project} hideApproveDecline={hideApproveDecline} auth={auth} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} cellType='daily' />
                    <DailyTaskCell dayNumber={11} department={department} tasks={tasks} departments={departments} project={project} hideApproveDecline={hideApproveDecline} auth={auth} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} cellType='daily' />
                    <DailyTaskCell dayNumber={12} department={department} tasks={tasks} departments={departments} project={project} hideApproveDecline={hideApproveDecline} auth={auth} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} cellType='daily' />
                    <DailyTaskCell dayNumber={13} department={department} tasks={tasks} departments={departments} project={project} hideApproveDecline={hideApproveDecline} auth={auth} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} cellType='daily' />                  

                  </div>
                ))}
              </div>
            </>
          )}
          
        </div>
      </Layout>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  thisProject: state.project,
  auth: state.auth,
  department: state.department,
  task: state.task
});

export default compose(requireAuth, withRouter, connect(mapStateToProps, { getProject, getProjectTasks, deleteProject, editProject, clearProjectError, getDepartments }))(ProjectView);

