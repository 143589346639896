// import React, { useEffect, useState } from 'react';
import React, { useState } from 'react';
import { connect } from 'react-redux';


import './styles.css';

const HuddleBoards = ({ toggleHuddleBoardVisibility}) => {

  const [isHuddleBoardVisible, setIsHuddleBoardVisible] = useState([false,false,false,false,false,false,false,false,false,false,false,false,false,false,false]);
 
  const toggleHuddleBoardCenterVisibility = (index) => {
    setIsHuddleBoardVisible(prevState => ({
      // ...prevState,
      [index]: !prevState[index]
    }));
  }

  return (
    <>
      <div className="huddle-boards-background-overlay" onClick={() => toggleHuddleBoardVisibility()}></div>
      <div className='huddle-boards-left-container'>
        <div className={`huddle-board ${isHuddleBoardVisible[0] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(0)}>Agenda</div>
        <div className={`huddle-board ${isHuddleBoardVisible[1] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(1)}>Project Plan</div>
        <div className={`huddle-board ${isHuddleBoardVisible[2] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(2)}>Risks</div>
        <div className={`huddle-board ${isHuddleBoardVisible[3] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(3)}>Opportunities</div>
        <div className={`huddle-board ${isHuddleBoardVisible[4] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(4)}>Team Behaviours</div>
      </div>
      <div className='huddle-boards-center-container'>
        <div className={`huddle-board-center ${isHuddleBoardVisible[0] ? 'huddle-board-center-active' : ''}`}>Agenda</div>
        <div className={`huddle-board-center ${isHuddleBoardVisible[1] ? 'huddle-board-center-active' : ''}`}>Project Plan</div>
        <div className={`huddle-board-center ${isHuddleBoardVisible[2] ? 'huddle-board-center-active' : ''}`}>Risks</div>
        <div className={`huddle-board-center ${isHuddleBoardVisible[3] ? 'huddle-board-center-active' : ''}`}>Opportunities</div>
        <div className={`huddle-board-center ${isHuddleBoardVisible[4] ? 'huddle-board-center-active' : ''}`}>Team Behaviours</div>
        <div className={`huddle-board-center ${isHuddleBoardVisible[5] ? 'huddle-board-center-active' : ''}`}>Weekly Priorities</div>
        <div className={`huddle-board-center ${isHuddleBoardVisible[6] ? 'huddle-board-center-active' : ''}`}>Golden Moments</div>
        <div className={`huddle-board-center ${isHuddleBoardVisible[7] ? 'huddle-board-center-active' : ''}`}>Pulse Check</div>
        <div className={`huddle-board-center ${isHuddleBoardVisible[8] ? 'huddle-board-center-active' : ''}`}>Non-TIP Actions</div>
        <div className={`huddle-board-center ${isHuddleBoardVisible[9] ? 'huddle-board-center-active' : ''}`}>Attendance</div>
        <div className={`huddle-board-center ${isHuddleBoardVisible[10] ? 'huddle-board-center-active' : ''}`}>Safety</div>        
        <div className={`huddle-board-center ${isHuddleBoardVisible[11] ? 'huddle-board-center-active' : ''}`}>Quality</div>               
        <div className={`huddle-board-center ${isHuddleBoardVisible[12] ? 'huddle-board-center-active' : ''}`}>Cost</div>        
        <div className={`huddle-board-center ${isHuddleBoardVisible[13] ? 'huddle-board-center-active' : ''}`}>People</div>        
        <div className={`huddle-board-center ${isHuddleBoardVisible[14] ? 'huddle-board-center-active' : ''}`}>Delivery</div>
      </div>
      <div className='huddle-boards-right-container'>
        <div className='huddle-board-column'>
          <div className={`huddle-board ${isHuddleBoardVisible[5] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(5)}>Weekly Priorities</div>
          <div className={`huddle-board ${isHuddleBoardVisible[6] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(6)}>Golden Moments</div>
          <div className={`huddle-board ${isHuddleBoardVisible[7] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(7)}>Pulse Check</div>
          <div className={`huddle-board ${isHuddleBoardVisible[8] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(8)}>Non-TIP Actions</div>
          <div className={`huddle-board ${isHuddleBoardVisible[9] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(9)}>Attendance</div>
        </div>
        <div className='huddle-board-column'>
          <div className={`huddle-board ${isHuddleBoardVisible[10] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(10)}>Safety</div>        
          <div className={`huddle-board ${isHuddleBoardVisible[11] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(11)}>Quality</div>               
          <div className={`huddle-board ${isHuddleBoardVisible[12] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(12)}>Cost</div>        
          <div className={`huddle-board ${isHuddleBoardVisible[13] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(13)}>People</div>        
          <div className={`huddle-board ${isHuddleBoardVisible[14] ? 'huddle-board-active' : ''}`} onClick={() => toggleHuddleBoardCenterVisibility(14)}>Delivery</div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({  
  auth: state.auth,
});

export default connect(mapStateToProps)(HuddleBoards);
