import React from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { useFormik } from 'formik';

import { registerUserWithEmail } from '../../store/actions/registerActions';
import { registerSchema } from './validation';
import './styles.css';

const Register = ({ auth, register: { isLoading, error }, history, registerUserWithEmail }) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      username: '',
      email: '',
      password: '',
    },
    validationSchema: registerSchema,
    onSubmit: (values) => {
      registerUserWithEmail(values, history);
    },
  });

  if (auth.isAuthenticated) return <Redirect to="/" />;

  return (
    <div className="register">
      <div className="container">
        {/* <h1>PODS Registration</h1> */}
        <div className="logo-register-container"><img className="logo-register" src={`${process.env.REACT_APP_BASE_URL}/Logo.png`} /></div>
        {/* <p>
          back to{' '}
          <Link className="bold" to="/">
            Home page
          </Link>
        </p> */}
        <div className="register-text">Register with email address</div>
        <form onSubmit={formik.handleSubmit} noValidate>
          {/* <h2>Create a new account</h2> */}
          <div>
            <input
              placeholder="Name"
              name="name"
              className="text"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <p className="error">{formik.errors.name}</p>
            ) : null}
            <input
              placeholder="Username"
              name="username"
              className="text"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
            />
            {formik.touched.username && formik.errors.username ? (
              <p className="error">{formik.errors.username}</p>
            ) : null}
            <input
              placeholder="Email address"
              name="email"
              className="text"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <p className="error">{formik.errors.email}</p>
            ) : null}
            <input
              placeholder="Password"
              name="password"
              className="text"
              type="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {formik.touched.password && formik.errors.password ? (
              <p className="error">{formik.errors.password}</p>
            ) : null}
          </div>
          {error && <p className="error">{error}</p>}
          <div>
            <button className="btn submit" type="submit" disabled={isLoading || !formik.isValid}>
              Sign Up
            </button>
          </div>
          <div>
            <p className="register-login-text">
            Have an account?{' '}
              <Link className="bold" to="/login">
                Log In
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  register: state.register,
});

export default compose(withRouter, connect(mapStateToProps, { registerUserWithEmail }))(Register);
