import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

// import '@fortawesome/fontawesome-svg-core/styles.css'

import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
// import Home from './pages/Home/Home';
import Dashboard from './pages/Dashboard/Dashboard';
import Archive from './pages/Archive/Archive';
import Profile from './pages/Profile/Profile';
import ProjectEdit from './pages/ProjectEdit/ProjectEdit';
import ProjectDepartmentsEdit from './pages/ProjectDepartmentsEdit/ProjectDepartmentsEdit';
import ProjectDepartmentsUsersEdit from './pages/ProjectDepartmentsUsersEdit/ProjectDepartmentsUsersEdit';
import DepartmentEdit from './pages/DepartmentEdit/DepartmentEdit';
import ProjectPodMastersEdit from './pages/ProjectPodMastersEdit/ProjectPodMastersEdit';
import ProjectView from './pages/ProjectView/ProjectView';
import Users from './pages/Users/Users';
import Admin from './pages/Admin/Admin';
import NotFound from './pages/NotFound/NotFound';
import Loader from './components/Loader/Loader';

import { logInUserWithOauth, loadMe } from './store/actions/authActions';

const App = ({ logInUserWithOauth, auth, loadMe }) => {
  useEffect(() => {
    loadMe();
  }, [loadMe]);

  //redosled hookova
  useEffect(() => {
    if (window.location.hash === '#_=_') window.location.hash = '';

    const cookieJwt = Cookies.get('x-auth-cookie');
    if (cookieJwt) {
      Cookies.remove('x-auth-cookie');
      logInUserWithOauth(cookieJwt);
    }
  }, []);

  useEffect(() => {
    if (!auth.appLoaded && !auth.isLoading && auth.token && !auth.isAuthenticated) {
      loadMe();
    }
  }, [auth.isAuthenticated, auth.token, loadMe, auth.isLoading, auth.appLoaded]);

  return (
    <>
      {auth.appLoaded ? (
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/users" component={Users} />
          {/* <Route path="/dashboard" component={Dashboard} /> */}
          <Route path="/archive" component={Archive} />
          <Route path="/notfound" component={NotFound} />
          <Route path="/admin" component={Admin} />
          <Route exact path="/project/edit/:projectId" component={ProjectEdit} />
          <Route exact path="/project/departments/edit/:projectId" component={ProjectDepartmentsEdit} />
          <Route exact path="/project/department/edit/:departmentId" component={DepartmentEdit} />
          <Route exact path="/project/department/edit/:departmentId/users" component={ProjectDepartmentsUsersEdit} />
          <Route exact path="/project/podmasters/edit/:projectId" component={ProjectPodMastersEdit} />          
          <Route exact path="/:username" component={Profile} />
          <Route exact path="/project/view/:projectId" component={ProjectView} />
          {/* <Route exact path="/" component={Home} /> */}
          <Route exact path="/" component={Dashboard} />
          <Route component={NotFound} />
        </Switch>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(connect(mapStateToProps, { logInUserWithOauth, loadMe }))(App);
