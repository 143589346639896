import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Project from '../Project/Project';
import Loader from '../Loader/Loader';

import { getProjects } from '../../store/actions/projectActions';
import './styles.css';

const ActiveProjectList = ({ auth, getProjects, project: { projects, isLoading, error } }) => {
  useEffect(() => {
    getProjects(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="project-list">
      { projects.length > 0 ? (
        <>
          <h2>Active Pods 
            {(auth.me?.role === 'SUPERADMIN') || (auth.me?.role === 'PODMASTER') ? (
              <>
                {' ('}
                <Link className="header-link" to="/archive">
                  View Archive
                </Link>
                {')'}
              </>
            ) : null }
          </h2>
          {error && <div className="error-center">{error}</div>}
          <div className="list">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {projects.map((project, index) => {
                  return <Project key={index} project={project} archived={false}/>;
                })}
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="no-projects">No active pods. Create a new project to get started or ask your Pod master to add you to a project.</div>
        </>
      )}
      
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
});

export default connect(mapStateToProps, { getProjects })(ActiveProjectList);
