import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_PROJECTS_LOADING,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,
  GET_PROJECT_LOADING,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAIL,
  ADD_PROJECT_LOADING,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  DELETE_PROJECT_LOADING,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  // ARCHIVE_PROJECT_LOADING,
  ARCHIVE_PROJECT_SUCCESS,
  // ARCHIVE_PROJECT_FAIL,
  UNARCHIVE_PROJECT_SUCCESS,
  EDIT_PROJECT_LOADING,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_FAIL,
  CLEAR_PROJECT_ERROR,
  GET_PROJECT_PODMASTERS_LOADING,
  GET_PROJECT_PODMASTERS_SUCCESS,
  GET_PROJECT_PODMASTERS_FAIL,
} from '../types';

export const getProjects = (archived) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROJECTS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);    
    const currentUser = getState().auth.me    
    var response = {};
    { currentUser.role === 'SUPERADMIN' ? (
      response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/archive/${archived}`, options)
    ) : (
      response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/user/${currentUser.id}/archive/${archived}`, options)
    )}
    { !archived ? (
      dispatch({
        type: GET_PROJECTS_SUCCESS,
        payload: { projects: response.data.projects, error: null },
      })
    ) : (
      dispatch({
        type: GET_PROJECTS_SUCCESS,
        payload: { projects: response.data.projects, error: null },
      })
    )}    
  } catch (err) {
    dispatch({
      type: GET_PROJECTS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getProject = (projectId, isEdit, history) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROJECT_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);    
    const currentUser = getState().auth.me    
    var response = {};

    response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/${projectId}`, options)
    const departmentsOfUser = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/departments/user/${currentUser.id}`, options)
    const userHasAccess = (departmentsOfUser.data.departments.some(dept => dept.project.id === response.data.project.id) || response.data.project.podMasters.some(pm => pm.id === currentUser.id));

    { currentUser.role === 'SUPERADMIN' || (currentUser.id === response.data.project.user.id || !isEdit ) || userHasAccess ? (
      dispatch({
        type: GET_PROJECT_SUCCESS,
        payload: { project: response.data.project, error: null },
      })
    ) : (
      // need to add logic to check whether the user has access to view the project      
        dispatch({
          type: GET_PROJECT_FAIL,
          payload: { error: "You don't have access to this project." },
        })
      
    )}   
    
  } catch (err) {
    if (err?.response.status === 404) {
      history.push('/notfound');
    }
    dispatch({
      type: GET_PROJECT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const addProject = (formData) => async (dispatch, getState) => {
  dispatch({
    type: ADD_PROJECT_LOADING,
    payload: { me: { ...getState().auth.me } },
  });
  try {
    const options = attachTokenToHeaders(getState);
    // console.log("FormData:", formData)
    const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/projects`, formData, options);

    dispatch({
      type: ADD_PROJECT_SUCCESS,
      payload: { project: response.data.project, error: null },
    });
  } catch (err) {
    dispatch({
      type: ADD_PROJECT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const deleteProject = (id) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_PROJECT_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.delete(`${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/${id}`, options);

    dispatch({
      type: DELETE_PROJECT_SUCCESS,
      payload: { project: response.data.project, error: null },
    });
  } catch (err) {
    dispatch({
      type: DELETE_PROJECT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const editProject = (id, formData) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_PROJECT_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    console.log("FormData:", formData);
    const response = await axios.put(`${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/${id}`, formData, options);
    
    if (formData.archived === true) {
      dispatch({
        type: ARCHIVE_PROJECT_SUCCESS,
        payload: { project: response.data.project, error: null },
      })
    } else if (formData.archived === false) {
      dispatch({
        type: UNARCHIVE_PROJECT_SUCCESS,
        payload: { project: response.data.project, error: null },
      })
    } else {
      dispatch({
        type: EDIT_PROJECT_SUCCESS,
        payload: { project: response.data.project, error: null },
      })
    }
  } catch (err) {
    dispatch({
      type: EDIT_PROJECT_FAIL,
      payload: { error: err?.response?.data.message || err.message, id },
    });
  }
};

export const getPodmasterForProject = (formData) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROJECT_PODMASTERS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    // console.log("Searching email:", formData.email);
    const responseUser = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/users/email/${formData.email}`, options);
    const responseProject = await axios.put(`${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/addpodmaster/${formData.projectID}`, responseUser.data.user, options);
    // const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/departments/project/${responseProject.data.department.project.id}`, options);

    dispatch({
      type: GET_PROJECT_PODMASTERS_SUCCESS,
      // payload: { departmentProfile: response.data.user },
      payload: { project: responseProject.data.project },
    });
    // dispatch({
    //   type: GET_DEPARTMENTS_SUCCESS,
    //   // payload: { departmentProfile: response.data.user },
    //   payload: { departments: response.data.departments },
    // });
  } catch (err) {    
    dispatch({
      type: GET_PROJECT_PODMASTERS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const deletePodmasterForProject = (formData) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROJECT_PODMASTERS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    // console.log("Searching email:", formData.email);
    const responseUser = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/users/email/${formData.email}`, options);
    const responseProject = await axios.put(`${process.env.REACT_APP_SERVER_BASE_URL}/api/projects/removepodmaster/${formData.projectID}`, responseUser.data.user, options);
    // const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/departments/project/${responseProject.data.department.project.id}`, options);

    dispatch({
      type: GET_PROJECT_PODMASTERS_SUCCESS,
      // payload: { departmentProfile: response.data.user },
      payload: { project: responseProject.data.project },
    });
    // dispatch({
    //   type: GET_DEPARTMENTS_SUCCESS,
    //   // payload: { departmentProfile: response.data.user },
    //   payload: { departments: response.data.departments },
    // });
  } catch (err) {    
    dispatch({
      type: GET_PROJECT_PODMASTERS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const clearProjectError = (id) => ({
  type: CLEAR_PROJECT_ERROR,
  payload: { id },
});
