import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { logOutUser } from '../../store/actions/authActions';
import './styles.css';

const Navbar = ({ auth, logOutUser, history }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const onLogOut = (event) => {
    event.preventDefault();
    logOutUser(history);
  };
  
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div>
        {/* <h2 className="logo">Pods</h2>
        <h3 className="tagLine">Project Execution</h3> */}
        <Link to="/"><img className="logo" src={`${process.env.REACT_APP_BASE_URL}/Logo-left.png`} /></Link>
      </div>
      <div className="nav-links-mobile">
        <div className={'nav-links-mobile-container'}>
          <FontAwesomeIcon icon={faBars} className={`nav-links-mobile-icon ${isMobileMenuOpen ? 'rotate-icon' : ''}`} onClick={toggleMobileMenu}/>
        </div>
        {isMobileMenuOpen && (
          <div className="nav-links-mobile-menu">
            <ul>
              <li>
                <Link className="mobile-nav-item" to="/">Home</Link>
              </li>
              {auth.me?.role === 'SUPERADMIN' && (
                <>
                  {/* <li className="nav-item">
                    <Link to="/admin">Admin</Link>
                  </li> */}
                  <li>
                    <Link className="mobile-nav-item" to="/users">Users</Link>
                  </li>
                </>
              )}
              <li>
                <Link className="mobile-nav-item" to={`/${auth.me.username}`}>Profile</Link>
              </li>
              <li onClick={onLogOut}>
                <a className="mobile-nav-item" href="#">Log out</a>
              </li>
            </ul>
          </div>
        )}
      </div>
      <ul className="nav-links flex-1">
        {/* <li className="nav-item">
          <Link to="/">Home</Link>
        </li> */}
        {auth.isAuthenticated ? (
          <>
            {/* <li className="nav-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="nav-item">
              <Link to="/archive">Archive</Link>
            </li>             */}
            {/* <li className="nav-item">
              <Link to={`/${auth.me.username}`}>Profile</Link>
            </li> */}
            {auth.me?.role === 'SUPERADMIN' && (
              <>
                {/* <li className="nav-item">
                  <Link to="/admin">Admin</Link>
                </li> */}
                <li className="nav-item">
                  <Link to="/users">Users</Link>
                </li>
              </>
            )}
            <li className="flex-1" />
            { auth.me && (
              <Link to={`/${auth.me.username}`} className="avatar"><img className="avatar" src={`${process.env.REACT_APP_SERVER_BASE_URL}${auth.me.avatar}`} /></Link>
            )}
            <li className="nav-item" onClick={onLogOut}>
              <a href="#">Log out</a>
            </li>
          </>
        ) : (
          <>
            <li className="flex-1" />

            <li className="nav-item">
              <Link to="/login">Login</Link>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(withRouter, connect(mapStateToProps, { logOutUser }))(Navbar);
