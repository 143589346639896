import React, { useState, useEffect, useRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment-timezone';

// import Loader from '../../components/Loader/Loader';
// import Layout from '../../layout/Layout';
import requireAuth from '../../hoc/requireAuth';

// import TaskForm from '../../components/TaskForm/TaskForm';
import Task from '../../components/Task/Task';
// import '../../components/DepartmentList/styles.css';

// import { getProject, deleteProject, editProject, clearProjectError } from '../../store/actions/projectActions';
import { editTask } from '../../store/actions/taskActions';
// import { getDepartments } from '../../store/actions/departmentActions';

import './styles.css';

import { useDrop } from 'react-aria';
// import type {TextDropItem} from 'react-aria';

// import socketIOClient from 'socket.io-client';


const DailyTaskCell = ({
  dayNumber, 
  department, 
  tasks, 
  departments, 
  project, 
  hideApproveDecline, 
  auth, 
  editTask,
  deconstructTask,
  deconstructValues,
  desconstructAudit,
  cellType
}) => {
  
    
  let [dropped, setDropped] = useState(null);
  let ref = useRef(null);
  let { dropProps, isDropTarget } = useDrop({
    ref,
    async onDrop(e) {
      
      let items = await Promise.all(        
        e.items
          .filter((item) =>
            item.kind === 'text' &&
            (item.types.has('text/plain') ||
              item.types.has('task'))
          )
          .map(async (item) => {            
            if (item.types.has('task')) {
              // console.log('onDrop e.item task', await item.getText('task'));
              return await item.getText('task');
            } else {              
              return { message: await item.getText('text/plain') };
            }         
          })
      );
      // console.log('onDrop items', items);
      setDropped(items);
    }    
  });

  // let message = ['Drop here'];
  useEffect(() => {
    if (dropped) {      
      // message = dropped.map((d) => {
        // console.log('dropped', dropped);
        // console.log('cellType', cellType);        
        
        try {
          let taskID = dropped[0].id;
          const task = deconstructTask(dropped[0]);          
          let taskNew = dropped[0];

          taskNew.dueDate = moment().add(dayNumber, 'days').format('YYYY-MM-DD');
          
          let setDelay = task.delay;

          if ((typeof task.dueDate !== 'object') && (task.approved === true)) {
            // console.log('task.dueDate', task.dueDate);
            // console.log('moment().add(dayNumber, \'days\')', moment().add(dayNumber, 'days'));
            // console.log('moment(task.dueDate)', moment(task.dueDate));
            if ( (moment().add(dayNumber, 'days').format('YYYY-MM-DD') > moment(task.dueDate).format('YYYY-MM-DD')) && (task.delay < 3) ) {
              setDelay = setDelay+1;              
            }
          }
          
          if (cellType === 'escalate') {
            setDelay = 3;
          }

          if (cellType === 'pacing') {
            setDelay = 0;
            taskNew.dueDate = null;
          }
          

          taskNew.department = department.id;
          taskNew.project = project.id;
          taskNew.assignedTo = taskNew.assignedTo.hasOwnProperty('id') ? taskNew.assignedTo.id : '';
                              
          // Reset assignedTo if department is changed
          if (taskNew.department !== task.department) {
            taskNew.assignedTo = '';
          }
          
          let audit = desconstructAudit(taskNew.audit);
          if (task.approved === true || taskNew.audit.length === 1) {                        
            audit.push({
              user: auth.me.id,
              action: 'updated',
              date: moment().toISOString(),
              old: task,
              new: deconstructValues(taskNew),
            });
          } else {
            let lastChange = audit.pop();
            lastChange.new = taskNew;
            audit.push(lastChange);
          }

          // console.log('task', task);
          // console.log('taskNew', taskNew);
          // console.log('audit', audit);

          editTask(taskID, { 
            title: taskNew.title, 
            description: taskNew.description, 
            complete: taskNew.complete, 
            assignedTo: taskNew.assignedTo === '' ? null : taskNew.assignedTo, 
            department: taskNew.department, 
            project: taskNew.project,
            approved: false,
            delay: setDelay, 
            dueDate:  moment(taskNew.dueDate).toISOString(),
            audit: audit,
          });
          
        } catch (err) {
          console.log('error', err);
        }      
        
        setDropped(null);    
        // return <div key={moment()}>{message}</div>;      
      // });
    }
}, [dropped]);

  if (cellType === 'escalate') {
    return (
      <div 
        {...dropProps}
        role="button"
        tabIndex={0}
        ref={ref}
        className={`project-view-page-body-row-task-cell droppable ${isDropTarget ? 'target' : ''} ${ tasks.some(task => (task.department.id === department.id) && (task.delay === 3)) ? '' : 'no-tasks' }`}>
        <div className="project-view-page-body-row-header-responsive-1"><div><b>Escalate</b></div></div>
        <div className='project-view-page-body-row-task-cell-tasks'>
          {tasks.filter(task => task.department.id === department.id).map(task => {
            if (task.delay === 3) {                          
              return <div key={task.id}><Task task={task} department={department} departments={departments} project={project} hideApproveDecline={hideApproveDecline} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} /></div>
            }
          })}
        </div>
      </div>
    );
  } else if (cellType === 'pacing') {
    return (
      <div
        {...dropProps}
        role="button"
        tabIndex={0}
        ref={ref} 
        className={`project-view-page-body-row-task-cell droppable ${isDropTarget ? 'target' : ''} ${ tasks.some(task => (task.department.id === department.id) && (task.dueDate === null || typeof task.dueDate === 'object')) ? null : 'no-tasks' }`}>
        <div className="project-view-page-body-row-header-responsive-1"><div><b>Pacing</b></div></div>
        <div className='project-view-page-body-row-task-cell-tasks'>
          {tasks.filter(task => task.department.id === department.id).map(task => {
            if (task.dueDate === null || typeof task.dueDate === 'object') {
              
              return <div key={task.id}><Task task={task} department={department} departments={departments} project={project} hideApproveDecline={hideApproveDecline} deconstructTask={deconstructTask} deconstructValues={deconstructValues} desconstructAudit={desconstructAudit} /></div>
            }
          })}
        </div>                  
      </div>
    );
  } else {
    return (
      <div 
        {...dropProps}
        role="button"
        tabIndex={0}
        ref={ref}
        className={`project-view-page-body-row-task-cell droppable ${isDropTarget ? 'target' : ''} ${ tasks.some(task => 
          (task.department.id === department.id) && 
          (task.dueDate !== null && typeof task.dueDate !== 'object' && task.delay < 3) &&
          (moment(task.dueDate).format('YYYY-MM-DD') === moment().add(dayNumber, 'days').format('YYYY-MM-DD'))
        ) ? '' : 'no-tasks' }`}
      >
        <div className="project-view-page-body-row-header-responsive-2"><div><b>{moment().add(dayNumber, 'days').format('dddd')}</b><br/>{moment().add(dayNumber, 'days').format('MMM DD')}</div></div>
        <div className='project-view-page-body-row-task-cell-tasks'>
          {/* {message} */}
          {tasks.filter(task => task.department.id === department.id).map(task => {
            if (task.dueDate !== null && typeof task.dueDate !== 'object' && task.delay < 3) {
              if (moment(task.dueDate).format('YYYY-MM-DD') === moment().add(dayNumber, 'days').format('YYYY-MM-DD')) {
                
                return <div key={task.id}><Task 
                  task={task} 
                  department={department} 
                  departments={departments} 
                  project={project} 
                  hideApproveDecline={hideApproveDecline} 
                  deconstructTask={deconstructTask}
                  deconstructValues={deconstructValues}
                  desconstructAudit={desconstructAudit}
                /></div>
              }
            }
          })}
        </div>
      </div>
    );
  }

  
}




const mapStateToProps = (state) => ({
  auth: state.auth,  
  task: state.task
});

export default compose(requireAuth, withRouter, connect(mapStateToProps, { editTask }))(DailyTaskCell);

