import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { CirclePicker } from 'react-color';

import { addDepartment } from '../../store/actions/departmentActions';
import { departmentFormSchema } from './validation';

import './styles.css';

const DepartmentForm = ({ addDepartment, projectId, department: { departments } }) => {
  const [color, setColor] = useState('#000000');
  const formik = useFormik({
    initialValues: {
      name: '',
      project: projectId,
      users: [],
      color: color,
      viewOnly: false,
      external: false,
    },
    validationSchema: departmentFormSchema,
    onSubmit: (values, { resetForm }) => {
      // console.log("Department form values:", values);
      addDepartment({ name: values.name, project: values.project, users: values.users, color: values.color, viewOnly: values.viewOnly, external: values.external });
      resetForm();
    },
  });

  const isSubmiting = departments.some((d) => d.id === 0);
  
  return (
    <div className="department-form">
      <h2>Add a department</h2>
      <form onSubmit={formik.handleSubmit}>
        <h4 className='label'>Department Name</h4>
        <input
          name="name"
          // cols="30"
          // rows="1"
          placeholder="Department name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          disabled={isSubmiting}
        />
        {formik.touched.name && formik.errors.name ? (
          <p className="error">{formik.errors.name}</p>
        ) : null}
        <h4 className='label'>Department Color</h4>
        <CirclePicker
          color={color}
          onChange={(color) => {
            formik.setFieldValue('color', color.hex);
            setColor(color.hex);
          }}
          className='department-form-color-field'
          colors={['#B4A7D6', '#B6D7A8', '#FFE599', '#EA9999', '#97BBDF', '#DFA6DF', '#ACDFDF', '#FFE5D4', '#CCC2A9', '#D6D6D6', '#9FB6C1', '#E5CDD2']}
        />
        {/* <input
          name="color"
          // cols="30"
          // rows="1"
          placeholder="Department color"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.color}
          disabled={isSubmiting}
        /> */}
        {formik.touched.color && formik.errors.color ? (
          <p className="error">{formik.errors.color}</p>
        ) : null}
        <h4 className='label'>View Only</h4>
        <p><i>Feature coming soon.</i></p>
        <select 
          name="viewOnly" 
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.viewOnly} 
          // TODO: Remove this once the feature is implemented
          disabled={true}
          className='department-form-select-field'
        >
          <option value={false}>No</option>
          <option value={true}>Yes</option>
        </select>
        {formik.touched.viewOnly && formik.errors.viewOnly ? (
          <p className="error">{formik.errors.viewOnly}</p>
        ) : null}
        <h4 className='label'>External</h4>
        <p><i>Feature coming soon.</i></p>
        <select 
          name="external" 
          onChange={formik.handleChange} 
          onBlur={formik.handleBlur} 
          value={formik.values.external} 
          // TODO: Remove this once the feature is implemented
          disabled={true}
          className='department-form-select-field'
        >
          <option value={false}>No</option>
          <option value={true}>Yes</option>
        </select>
        {formik.touched.external && formik.errors.external ? (
          <p className="error">{formik.errors.external}</p>
        ) : null}
        <br />
        <input type="submit" className="btn save-btn" value="Add Department" disabled={isSubmiting} />
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  department: state.department,
});

export default connect(mapStateToProps, { addDepartment })(DepartmentForm);
